const tableSettingsState = {
  fieldByField: {
    tableSettingsKeyName: "fieldByField",
    // Columns visible by default
    fieldName: true,
    currentCropName: true,
    varietyName: true,
    costOfProduction: true,
    costPerHa: true,
    fieldYield: true,
  },
};

const tableSettingsGetters = {
  GET_FIELD_BY_FIELD(state) {
    return {...(JSON.parse(localStorage.getItem("tableSettings"))?.fieldByField || state.fieldByField)};
  },
  GET_ALL_TABLE_SETTINGS(state) {
    return {...(JSON.parse(localStorage.getItem("tableSettings")) || state)};
  },
};

const tableSettingsMutations = {
  SET_ALL_TABLE_SETTINGS(state, allTableSettings) {
    state = {...state, ...allTableSettings};
  },

  SET_INDIVIDUAL_TABLE_SETTINGS(state, tableSettings) {
    state[tableSettings.tableSettingsKeyName] = tableSettings;
  },
};

const tableSettingsActions = {
  initialiseTheStore({ commit, dispatch }) {
    const localStorageData = JSON.parse(localStorage.getItem("tableSettings"));

    if (localStorageData) {
      dispatch("settings/captureEvent",
        {eventName: "store populated from localStorage", payload: localStorageData},
        {root: true},
      );
      commit("SET_ALL_TABLE_SETTINGS", localStorageData);
    }
  },
};

const tableSettingsModule = {
  namespaced: true,
  state: tableSettingsState,
  getters: tableSettingsGetters,
  mutations: tableSettingsMutations,
  actions: tableSettingsActions,
};

export default tableSettingsModule;
