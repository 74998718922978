let adminRoutes = [
  {
    path: "/manager/farms",
    component: () => import("@c/Manager/ManagerFarms"),
    name: "Farms",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/suppliers",
    component: () => import("@c/Manager/ManagerSuppliers"),
    name: "All Suppliers",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/partners",
    component: () => import("@c/Manager/ManagerPartners"),
    name: "Partners",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/requests",
    component: () => import("@c/Manager/ManagerRequests"),
    name: "Admin Requests",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/quotes",
    component: () => import("@c/Manager/ManagerQuotes"),
    name: "Admin Quotes",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/orders",
    component: () => import("@c/Manager/ManagerOrders"),
    name: "Admin Orders",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/users",
    component: () => import("@c/Manager/ManagerUsers"),
    name: "All Users",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/products",
    component: () => import("@c/Manager/ManagerProducts"),
    name: "Products",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/crops",
    component: () => import("@c/Manager/ManagerCropList"),
    name: "Crops",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/categories",
    component: () => import("@c/Manager/ManagerCategories"),
    name: "Categories",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/otherproducts",
    component: () => import("@c/Manager/ManagerOtherProducts"),
    name: "Other Products",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/settings",
    component: () => import("@c/Manager/Settings"),
    name: "Platform Settings",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/release-notes",
    component: () => import("@c/shared/ReleaseNotes"),
    name: "Release Notes",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/farmtypes",
    component: () => import("@c/Manager/FarmTypes"),
    name: "Farm Types",
  },
  {
    path: "/manager/statistics",
    component: () => import("@c/Manager/Statistics"),
    name: "Platform Statistics",
  },

  {
    path: "/manager/message-groups",
    component: () => import("@c/Manager/MessageMultipleGroups"),
    name: "Message Groups",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/ingestion/upload",
    component: () => import("@c/Manager/ingestion/IngestionUpload"),
    name: "Yagro Ingestion Upload",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/ingestion/list",
    component: () => import("@c/Manager/ingestion/IngestionDataList"),
    name: "Yagro Ingestion Data List",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/ingestion/rules",
    component: () => import("@c/Manager/ingestion/RuleEditor"),
    name: "Ingestion Rules",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/ingestion/fields",
    component: () => import("@/pages/admin/ingestion/AdminFieldsOverview"),
    name: "Ingestion Fields",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/ingestion/group/:groupId/fields/:fieldId",
    component: () => import("@/pages/admin/ingestion/AdminFieldDetailedOverview"),
    name: "Ingestion Field Detailed View",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/manager/reports/insights",
    component: () => import("@c/Manager/PointsOfInterest"),
    name: "Yagro Insights",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/reports/view",
    component: () => import("@c/Manager/ReportsViewer"),
    name: "Yagro Reports Viewer",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/reports/datainspector",
    component: () => import("@c/Manager/DataInspector"),
    name: "Yagro Data Inspector",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/reports/pdfs",
    component: () => import("@/pages/admin/tracker/CropReports"),
    name: "PDF Reports",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/reports/view-farm-specific",
    component: () => import("@c/Manager/FarmSpecificReportsViewer"),
    name: "Yagro Reports Viewer Farm Specific",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/products/upload",
    component: () => import("@c/Manager/UploadProducts"),
    name: "Upload Products",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/deliverylocations",
    component: () => import("@c/Manager/ManagerDeliveryLocations"),
    name: "All Delivery Locations",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/group/create/:groupType",
    component: () => import("@c/Manager/ManagerGroupCreate"),
    name: "Create Farm",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/manager/otherproduct/:otherProductId",
    component: () => import("@c/Manager/OtherProductDetails"),
    name: "Other Product Details",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/manager/group/edit/:groupType/:groupId",
    component: () => import("@c/Manager/ManagerGroupCreate"),
    name: "Edit Farm",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/user/profile/:userId/:userEmail",
    component: () => import("@c/shared/Profile"),
    name: "User Profile",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/user/profile",
    component: () => import("@c/shared/Profile"),
    name: "Current User Profile",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/users/edit_profile/:userId/:userEmail",
    component: () => import("@c/shared/EditProfile"),
    name: "Edit User Profile",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/manager/user/create",
    component: () => import("@c/Manager/CreateUser"),
    name: "Create User",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/request/:requestId",
    component: () => import("@c/Manager/RequestDetails"),
    name: "Request Details",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/manager/farm/profile/:groupId/:groupUuid",
    component: () => import("@c/Manager/FarmAnalytics"),
    name: "Farm Profile",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/manager/supplier/profile/:groupId/:groupUuid",
    component: () => import("@c/Manager/SupplierAnalytics"),
    name: "Supplier Profile",
    props: true,
    meta: { adminOnly: true },
  },
  {
    path: "/manager/pricecheck/main",
    component: () => import("@c/Manager/PriceCheckAdmin"),
    name: "Price Check Admin",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/pricecheck/main/season/:season",
    component: () => import("@c/Manager/PriceCheckAdmin"),
    name: "Price Check Admin - Season",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/pricecheck/data",
    component: () => import("@c/Manager/pricecheck/PriceCheckList"),
    name: "Price Check Data",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/pricecheck/seasons",
    component: () => import("@c/Manager/pricecheck/PriceCheckSeasons"),
    name: "Price Check Seasons",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/pricecheck/distributors",
    component: () => import("@c/Manager/pricecheck/DistributorsList"),
    name: "Price Check Distributors",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/virtual-groups",
    component: () => import("@/pages/admin/virtualgroups/Manager"),
    name: "Admin Virtual Groups",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/jobs",
    component: () => import("@/pages/admin/Jobs"),
    name: "Admin Jobs",
    meta: { adminOnly: true },
  },
  // ===================== Product Routes =======================
  {
    path: "/products/analyse/:productId",
    component: () => import('@c/Product/ProductBench'),
    name: "Products Analysis",
    props: true,
    meta: { adminOnly: true },
  },

  // =================== Supplier Routes ======================
  {
    path: "/supplier/farms/",
    component: () => import('@c/Manager/SupplierFarms'),
    name: "Supplier Farms",
    props: true,
    meta: { adminOnly: true },
  },

  {
    path: "/admin-dashboard",
    redirect: () => '/dashboard',
    name: "Admin Dashboard",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/ingestion/holding",
    component: () => import('@c/Manager/ingestion/HoldingArea/HoldingAreaListPage'),
    name: "Holding Area",
    meta: { adminOnly: true },
  },
  {
    path: "/manager/ingestion/holding/edit/:ingestionProcessId/:documentId",
    component: () => import('@c/Manager/ingestion/HoldingArea/HoldingAreaViewAndEditDataPage'),
    name: "Ingestion Edit Data",
    meta: { adminOnly: true },
  },
  {
    path: "/marketwatch/edit-inputs",
    component: () => import('@/pages/admin/MarketwatchInputsEditTable'),
    name: "Edit Inputs Data",
    meta: { adminOnly: true },
  },
];

export default adminRoutes;
