import Notifications from "../../notifications";
import store from "../../store";
import AbstractAuthBackend from "./abstract";
import axios from "axios";
import NetworkModule from "@/lib/NetworkModule";
import * as Sentry from "@sentry/browser";

class AuthServiceBackend extends AbstractAuthBackend {
  constructor(auth_storage) {
    super();
    this.auth_storage = auth_storage;
    this.refreshing = false;
  }

  async login(error_callback, success_callback, creds, admin = false) {
    const login_url = store.state.settings.apiGatewayBase + "/auth/login";
    const storage = this.auth_storage;

    try {
      const login_response = await axios.post(login_url, creds, {
        validateStatus: (status) => status === 200 || status === 401,
      });

      if (!login_response) {
        throw new Error("No response received from login request.");
      }

      if (login_response.status && login_response.status !== 200) {
        const errorMessage =
          login_response.status === 401
            ? "Unable to login with the provided credentials."
            : "Sorry, something went wrong and we couldn't log you in. Please try again.";
        error_callback({
          __all__: [errorMessage],
        });
        return;
      }

      const payload = {
        group: login_response.data.user.groups?.length
          ? login_response.data.user.groups[0]
          : {},
        id: login_response.data.user.id,
        language: login_response.data.user.language,
        force_reset_password: login_response.data.user.force_reset_password,
        current_terms_accepted: login_response.data.user.current_terms_accepted,
        release_notes_read: login_response.data.user.release_notes_read,
        is_admin: login_response.data.user.permissions.includes("YAGRO_ADMIN"),
        user_email: login_response.data.user.email,
      };

      storage.saveToken(
        creds.remember,
        login_response.data.auth_token,
        payload,
      );

      const storageData = storage.getStorage();
      storageData["refresh_token"] = login_response.data.refresh_token;
      storageData["active_auth_backend"] = "AuthService";
      storageData["isAdmin"] =
        login_response.data.user.permissions.includes("YAGRO_ADMIN") ||
        login_response.data.user.permissions.includes("YAGRO_ENG_ADMIN");
      storageData["permissions"] = login_response.data.user.permissions;
      Notifications.storeDeviceToken();

      const groupData = await new NetworkModule.SingleCancelRequest().get(
        `${store.state.settings.apiGatewayBase}/business/autocomplete/list-groups`,
      );
      store.commit("user/GROUP_LIST", groupData);
      if (
        !login_response.data.user.permissions.includes("YAGRO_ADMIN") &&
        groupData?.length
      ) {
        store.commit("user/CHANGE_GROUP", groupData[0]);
      }
      store.commit("user/LANGUAGE", payload.language);
      store.commit("user/FORCE_RESET_PASSWORD", payload.force_reset_password);
      store.commit("user/CURRENT_TERMS_ACCEPTED", payload.current_terms_accepted);
      store.commit("user/RELEASE_NOTES_READ", payload.release_notes_read);
      store.commit("user/USER_EMAIL", payload.user_email);
      store.commit("user/USER_AUTHENTICATED", payload.group);

      const scope = Sentry.getCurrentScope();

      if (payload.group) {
        scope.setTag("selected_group_name", payload.group.name);
        scope.setTag("selected_group_type", payload.group.type);
        scope.setTag("selected_group_id", payload.group.uuid);
      }

      if (payload.language) {
        scope.setTag("user_language", payload.language);
      }

      scope.setUser({
        email: creds.username,
      });

      success_callback(login_response);
    } catch (error) {
      console.error(error);
      error_callback({
        __all__: [
          "Sorry, something went wrong and we couldn't log you in. Please try again.",
        ],
      });
    }
  }

  logout(redirectUrl) {
    const storage = this.auth_storage.getStorage();
    if (store.state.user.authenticated) {
      const LOGOUT_URL = store.state.settings.apiGatewayBase + "/auth/logout";
      // unregister this device for push notifications
      Notifications.unregisterDeviceToken();
      var instance = axios.create();

      instance.defaults.headers.common["Authorization"] =
        "Bearer " + storage.getItem("id_token");
      if (storage) {
        storage.removeItem("id_token");
        storage.removeItem("id_payload");
        storage.removeItem("refresh_token");
        storage.removeItem("active_auth_backend");
        storage.removeItem("isAdmin");
        storage.removeItem("permissions");
      }
      store.commit("user/LANGUAGE", null);
      store.commit("user/USER_NOT_AUTHENTICATED");
      store.commit("user/ACTIVE_AUTH_BACKEND", null);
      store.commit("user/FORCE_RESET_PASSWORD", null);
      store.commit("user/CURRENT_TERMS_ACCEPTED", null);
      store.commit("user/RELEASE_NOTES_READ", null);
      store.commit("user/USER_EMAIL", null);

      instance.post(LOGOUT_URL, {})

      if (redirectUrl) {
        vm.navigateTo({
          path: redirectUrl,
          query: {
            t: +new Date(),
          },
        });
      } else {
        vm.navigateTo({
          name: "Login",
          query: {
            t: +new Date(),
          },
        });
      }
    }
  }

  refresh_promise() {
    let self = this;
    const storage = self.auth_storage.getStorage();
    if (self.refreshing) {
      return Promise.reject();
    }

    if (!storage.getItem("refresh_token")) {
      return Promise.reject();
    }

    const REFRESH_URL = store.state.settings.apiGatewayBase + "/auth/refresh";
    self.refreshing = true;

    // Attempts to refresh the token and logs out if fails
    let instance = axios.create();

    instance.defaults.headers.common["Authorization"] =
      "Bearer " + storage.getItem("refresh_token");

    const refreshingCall = instance.post(REFRESH_URL, {}).then(
      (response) => {
        let refreshResponse = response.data;
        // This means it succeeded - maybe we need to force a refresh of the route?
        storage.setItem("id_token", refreshResponse.auth_token);
        storage.setItem("refresh_token", refreshResponse.refresh_token);

        self.refreshing = false;
        return Promise.resolve(true);
      },
      (error) => {
        self.refreshing = false;
        return Promise.reject(error);
      },
    );
    return refreshingCall;
  }

  refresh(logoutFunc = null) {
    let self = this;
    this.refresh_promise().then(
      function() { },
      function() {
        if (logoutFunc) {
          logoutFunc();
        } else {
          self.logout("/users/login");
        }
      },
    );
  }
}

export default AuthServiceBackend;
