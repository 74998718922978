<script>
/**
 * User State Mixin
 * Exposes dynamic user state from VueX and provides helper
 * functions for using it
 */
import FeatureModule from "../../../lib/FeatureModule";
import GroupModule from "../../../lib/GroupModule";
import functions from "../functions";
import auth from "../../../auth";
import store from "@/store";
import { changeBranding, getBranding } from "@/branding";

const FEATURE_TYPE = FeatureModule.FEATURE_TYPE;
const GROUP_TYPE = GroupModule.GROUP_TYPE;

export default {
  computed: {
    user_authenticated() {
      return auth.user_authenticated();
    },
    group_type() {
      return store.state.user.group.type;
    },
    group_obj() {
      return store.state.user.group;
    },
    group_id() {
      return store.state.user.group.id;
    },
    group_uuid() {
      return store.state.user.group.uuid;
    },
    group_name() {
      return store.state.user.group.name;
    },
    group_features() {
      return store.state.user.group.features;
    },
    isSupplier() {
      return this.isSupplierGroup();
    },
  },

  methods: {
    isFarmGroup() {
      return (
        this.group_type === GROUP_TYPE.FARM ||
        this.group_type === GROUP_TYPE.AGGREGATOR
      );
    },

    isSupplierGroup() {
      return this.group_type === GROUP_TYPE.SUPPLIER;
    },

    isProductGroup() {
      return this.group_type === GROUP_TYPE.PRODUCT;
    },

    isPartner() {
      return this.group_type === GROUP_TYPE.PARTNER;
    },

    changeGroup(group) {
      const oldBranding = getBranding();
      changeBranding(group["branding_id"], window.vm);
      const newBranding = getBranding();
      this.$store.commit("user/CHANGE_GROUP", group);
      this.$store.dispatch("requests/CLEAR_OPTIONS");

      if (oldBranding !== newBranding && newBranding === "yagro") {
        this.$router.go(0);
      }
    },

    groupHasFeature(features, requiredValues) {
      return functions.groupHasFeature(
        features,
        this.group_features,
        requiredValues,
      );
    },

    hasExplorerFeature() {
      return this.groupHasFeature(FEATURE_TYPE.EXPLORER, "enabled");
    },

    hasMarketwatchFeature() {
      return this.groupHasFeature(FEATURE_TYPE.MARKETWATCH, "enabled");
    },

    hasMarketplaceFeature() {
      return this.groupHasFeature(FEATURE_TYPE.MARKETPLACE, "enabled");
    },

    hasBusinessTrackerFeature() {
      return this.groupHasFeature(FEATURE_TYPE.BUSINESSTRACKER, "enabled");
    },

    hasBreakevenFeature() {
      return this.groupHasFeature(
        FEATURE_TYPE.BREAKEVEN_CALCULATION,
        "enabled",
      );
    },

    hasDataUploadFeature() {
      return this.groupHasFeature(FEATURE_TYPE.DATA_UPLOAD, "enabled");
    },

    hasEndUseFilterFeature() {
      return this.groupHasFeature(FEATURE_TYPE.END_USE_FILTER, "enabled");
    },
    hasGrossMarginFilterFeature() {
      return this.groupHasFeature(FEATURE_TYPE.GROSS_MARGIN_FILTER, "enabled");
    },
    hasAnalyticsReportsFeature() {
      return this.groupHasFeature(FEATURE_TYPE.ANALYTICS_REPORTS, "enabled");
    },
  },
};
</script>
