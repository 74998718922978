import accounting from "accounting";
import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range.js";
import store from "@/store";

// Date functions
export const currentDate = () => {
  const currentDateTime = moment();
  return moment({
    year: currentDateTime.year(),
    month: currentDateTime.month(),
    day: currentDateTime.date(),
  })
}

export const diffDates = (start_str, end_str) => {
  let end = typeof end_str === "undefined" ? moment() : end_str;
  const start = moment(start_str);
  end = moment(end);
  return moment.duration(start.diff(end)).asDays();
}

export const diffDateTime = (start_str, end_str) => {
  let end = typeof end_str === "undefined" ? moment() : end_str;
  const start = moment(start_str);
  end = moment(end);
  return moment.duration(start.diff(end)).asSeconds();
}

export const formatDate = (date, invalid_string = "N/A", input_format = undefined, output_format = "DD-MM-YYYY") => {
  const mom = moment(date, input_format);
  if (!date || !mom.isValid()) {
    return invalid_string;
  }
  return mom.format(output_format);
}

export const formatDateWithMonths = (date, invalid_string = "N/A") => {
  const mom = moment(date);
  if (!mom.isValid()) {
    return invalid_string;
  }
  return mom.format("DD MMMM YYYY");
}

export const formatDateTime = (date, invalid_string = "N/A") => {
  const mom = moment(date);
  if (!mom.isValid()) {
    return invalid_string;
  }
  return mom.format("DD-MM-YYYY HH:mm:ss");
}

export const formatDateTimeWithMonths = (date, invalid_string = "N/A") => {
  const mom = moment(date);
  if (!mom.isValid()) {
    return invalid_string;
  }
  return mom.format("Do MMMM YYYY [at] HH:mm");
}

export const formatExpiryDateTime = (date) => {
  let locale = window.vm.$i18n.locale;
  moment.locale(locale);

  const expiryDate = moment.utc(date);

  if (!expiryDate.isValid()) {
    throw "invalid_string";
  }

  return expiryDate.fromNow();
}

export const formatDuration = (duration_secs, short_format = true) => {
  const hours = Math.floor(duration_secs / 60 / 60);
  const minutes = Math.floor(duration_secs / 60) % 60;
  const minutes_formatted = `00${minutes}`.slice(-2);
  if (short_format) {
    return `${hours}h:${minutes_formatted}`;
  }
  return `${hours} hours ${minutes_formatted} minutes`;
}

export const formatPaymentDate = (date, invalid_string = "N/A") => {
  if (!date) {
    return "Standard";
  }
  const mom = moment(date);
  if (!mom.isValid()) {
    return invalid_string;
  }
  return moment(date).format("MMMM YYYY");
}

// Money functions
export const currencySymbol = () => {
  return store.getters["settings/currency_symbol"] || "£";
}

export const defaultLanguageCode = () => {
  return store.getters["settings/default_language_code"] || "en_gb";
}

export const moneyFormat = (amount) => {
  const amount_format = amount || 0;
  const current_amount = accounting.toFixed(amount_format, 2);
  return accounting.formatMoney(
    current_amount,
    currencySymbol(),
    2,
  );
}

export const moneyFormatRounded = (amount) => {
  const amount_format = amount || 0;
  const current_amount = accounting.toFixed(amount_format, 2);
  return accounting.formatMoney(
    current_amount,
    currencySymbol(),
    0,
  );
}

export const moneyFormatAccurate = (amount) => {
  // This will allow for up to 4 decimal places for more accurate prices
  const amount_format = amount || 0;
  let decimal_places = 2;
  if (Number(amount_format).toFixed(2) - amount_format !== 0) {
    decimal_places = 4;
  }
  return accounting.formatMoney(
    amount_format,
    currencySymbol(),
    decimal_places,
  );
}

export const pricePerUnit = (price, quantity) => {
  if (!quantity) {
    return "N/A";
  }
  const total = price / quantity;
  return moneyFormatAccurate(total);
}

export const isOpenRequest = (request) => {
  const open_status = request.status === "requested";
  if (!open_status) {
    return false;
  }
  // Check the request hasn't expired
  if (request.required_by !== undefined) {
    const currentDate = moment();
    const required_by = moment(request.required_by);
    const difference = moment
      .duration(required_by.diff(currentDate))
      .asDays();
    if (difference <= 0) {
      return false;
    }
  }
  return true;
}

export const isExpiredQuote = (quote) => {
  const currentDate = moment();
  const expiry_date = moment(quote.expiry_date);
  const expiry_difference = moment
    .duration(expiry_date.diff(currentDate))
    .asDays();
  return expiry_difference < 0;
}

export const orderObjectsBy = (property) => {
  let sortOrder = 1;
  let key = property;
  if (key[0] === "-") {
    sortOrder = -1;
    key = property.substr(1);
  }
  return (aS, bS) => {
    const a = Number(aS[key]);
    const b = Number(bS[key]);
    let result = 0;
    if (a < b) {
      result = -1;
    } else if (a > b) {
      result = 1;
    }
    return result * sortOrder;
  };
}

export const removeDuplicates = (array, key) => {
  if (!(array instanceof Array) || (key && typeof key !== "string")) {
    return false;
  }
  if (key && typeof key === "string") {
    return array.filter(
      (obj, index, arr) =>
        arr.map(mapObj => mapObj[key]).indexOf(obj[key]) === index,
    );
  }
  return array.filter((item, index, arr) => arr.indexOf(item) === index);
}

export const shortenUnits = (word) => {
  switch (word) {
  case "litres":
    return "L";
  case "kilograms":
    return "kg";
  case "grams":
    return "g";
  case "millilitres":
    return "ml";
  case "milligrams":
    return "mg";
  case "acres":
    return "acre";
  case "hectares":
    return "ha";
  case "tonnes":
    return "t";
  default:
    return word;
  }
}

export const lengthenUnits = (word) => {
  switch (word) {
  case "L":
  case "l":
    return "litre";
  case "kg":
    return "kilogram";
  case "g":
    return "gram";
  case "ml":
    return "millilitres";
  case "mg":
    return "milligrams";
  case "ac":
    return "acres";
  case "ha":
    return "hectare";
  case "Mg":
  case "t":
    return "tonne";
  default:
    return word;
  }
}

export const scalePriceToUnits = (price, unitsTo) => {
  // The units supplied will always be l or g (enforced in the product_pricing table)
  switch (unitsTo) {
  case "mg":
    return price /= 1000
  case "kg":
    return price *= 1000
  case "Mg":
    return price *= (1000 * 1000)
  case "ml":
    return price /= 1000
  default:
    return price
  }
}

// Location functions
export const resolveSiteName = (siteName) => {
  return siteName == "production" ? null : siteName;
}

export const featureIsOneOfValue = (featureValue, requiredValues) => {
  for (let requestValue of Object.entries(requiredValues)) {
    if (requestValue == featureValue) {
      return true;
    }
  }
  return false;
}

export const groupHasFeature = (features, groupFeatures, requiredValue) => {
  if (!requiredValue) {
    throw new Error(`Missing requiredValue for feature ${features}`)
  }

  // If passed a string then only one feature to check
  // If passed an object, look for operator and features keys and determine feature availability from that.
  if (typeof features == "string") {
    if (groupFeatures && groupFeatures[features]) {
      return groupFeatures[features] === requiredValue;
    }
  } else if (typeof features == "object") {
    if (features.operator === "or") {
      for (const feat in features.features) {
        if (
          groupFeatures &&
          groupFeatures[features.features[feat]] === requiredValue
        ) {
          return true;
        }
      }
    } else if (features.operator === "and") {
      for (const feat in features.features) {
        if (
          groupFeatures &&
          groupFeatures[features.features[feat]] !== requiredValue) {
          return false;
        }
      }
      return true;
    }
  }
  return false;
}

export const copyToClipboard = (text) => {
  const input = document.createElement("input");
  input.value = text;
  document.body.appendChild(input);
  input.select();
  input.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(input);
  vm.showMessage("success", "Copied to clipboard");
}

export const forceFileDownload = (response, filename = null) => {
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement("a");
  link.href = url;
  if (filename) {
    link.setAttribute("download", filename);
  }
  document.body.appendChild(link);
  link.click();
}

export const downloadWithAxios = (url, filename, params = {}) => {
  const get_params = Object.entries(
    params).map(
    ([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`,
  ).join('&');

  let full_url = url;
  if (get_params.length > 0) {
    full_url = `${full_url}?${get_params}`
  }
  let req_params = {
    method: "get",
    url: full_url,
    timeout: 180000, // 3 mins
    responseType: "arraybuffer",
  }
  window.vm
    .$http(req_params)
    .then(response => {
      forceFileDownload(response, filename);
    });
}

export const sortByDateTime = (x, y) => {
  x = moment(x);
  y = moment(y);

  if (x > y) {
    return 1;
  } else if (y > x) {
    return -1;
  } else if (x === y) {
    return 0;
  }
}

export const sortByNumeric = (x, y) => {
  if (x > y) {
    return 1;
  } else if (x < y) {
    return -1;
  } else if (x === y) {
    return 0;
  }
}

export const sortBySeason = (a, b) => {
  return (a.year < b.year) ? 1 : (a.year === b.year) ? ((a.season > b.season) ? 1 : -1) : -1
}
export const apiGet = async (url) => {
  return window.vm.$http
    .get(url)
    .then(response => response.json())
    .catch(error => {
      if (error && error.response && error.response.data !== undefined) {
        throw error;
      }
      throw error;
    });
}

export const getIngestionType = async (uuid) => {
  return apiGet(`${store.state.settings.apiLegacyBase}/api/manager/ingestion-type/${uuid}`);
}

export const getGroup = async (uuid) => {
  return apiGet(`${store.state.settings.apiLegacyBase}/api/group/${uuid}`);
}

export const categorySort = (l, r) => {
  // First yield
  if (l.toLowerCase() === "yield") {
    return -1;
  }
  if (r.toLowerCase() === "yield") {
    return 1;
  }

  // Then seed
  if (l.toLowerCase() === "seed") {
    return -1;
  }
  if (r.toLowerCase() === "seed") {
    return 1;
  }

  // Chem last
  if (l.match(/chemicals?/i)) {
    return 1;
  }
  if (r.match(/chemicals?/i)) {
    return -1;
  }

  return 0;
}

export const nameSort = (a, b) => {
  const nameA = a.name.toLowerCase(),
    nameB = b.name.toLowerCase();
  if (nameA < nameB) return -1;
  if (nameA > nameB) return 1;
  return 0; //default return value (no sorting)
}

export const median = (numbers) => {
  if (numbers.length === 0) {
    return null
  }
  const sorted = numbers.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }

  return sorted[middle];
}

export const average = (arr) => {
  return arr.reduce((a, b) => a + b, 0) / arr.length
}

//Farm calculation functions

/**
  * Calculates and returns the adjusted minimum and maximum prices by excluding outliers.
  * Outliers are determined based on the interquartile range (IQR) method.
  *
  * @param {Object} params - The parameters for the calculation.
  * @param {number} [params.minPricePerUnit] - The minimum price per unit.
  * @param {number} [params.maxPricePerUnit] - The maximum price per unit.
  * @param {number} params.percentile25 - The 25th percentile price.
  * @param {number} params.percentile75 - The 75th percentile price.
  * @returns {Object} Object containing the adjusted `minPrice` and/or `maxPrice`, excluding outliers.
  *                   If `minPricePerUnit` or `maxPricePerUnit` are not provided, their corresponding return properties are omitted.
  */
export const calcPriceExcludingOutliers = ({
  minPricePerUnit,
  maxPricePerUnit,
  percentile25,
  percentile75,
}) => {
  const iqr = percentile75 - percentile25;
  const prices = {}

  if (minPricePerUnit !== undefined) {
    prices.minPrice = Math.max(minPricePerUnit, percentile25 - 1.5 * iqr);
  };
  if (maxPricePerUnit !== undefined) {
    prices.maxPrice = Math.min(maxPricePerUnit, percentile75 + 1.5 * iqr);
  };

  return prices;
}

/**
 * Calculates the average variety yield when user enters their estimated values by variety for yield
 *
 * @param { Array<Object> } An array of objects where each object
 * represents a variety and has the yield value in the property of magnitude as well as the area.
 * @returns { number } The average variety yield rounded to two decimal places
 * or 0 if totalArea is less than zero
 */
export const averageVarietyYield = (varieties) => {
  let totalProduction = 0;
  let totalArea = 0;
  for (const variety of varieties) {
    if (variety.magnitude) {
      totalProduction += Number(variety.magnitude) * Number(variety.area);
      totalArea += Number(variety.area);
    }
  }
  if (totalArea > 0) {
    return Math.round((totalProduction / totalArea) * 100) / 100; // rounded to 2 d.p
  } else {
    return 0;
  }
}
