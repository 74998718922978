import NetworkModule from "../lib/NetworkModule";
import store from "../store";
import { ClientJS } from 'clientjs';
const client = new ClientJS();


const DEVICE_TOKEN_URL = `/legacy/api/shared/store-device-token`;
const UNREGISTER_DEVICE_TOKEN_URL = `/legacy/api/shared/remove-device-token`;

const notifications = {
  network_request: new NetworkModule.SingleCancelRequest(),

  /**
   * Register device token to receive push notifications
   * Token is stored in user store so that this device can be unregistered on logout
   */
  storeDeviceToken() {
    // Get old token so that we can regenerate the session
    const old_pushy_session = {
      remove: false,
      token: "",
    };
    if (localStorage.getItem("pushyToken") !== null) {
      old_pushy_session.token = localStorage.getItem("pushyToken");
      old_pushy_session.remove = true;
    }
    // Register device for push notifications
    const device_token = localStorage.getItem("pushy_device_token");
    const client_info = {
      os: client.getOS(),
      user_agent: client.getUserAgent(),
      browser: client.getBrowser(),
    };
    const data = {
      token: device_token,
      device: client_info,
      previous_session: old_pushy_session,
      platform: "web",
    };
    new NetworkModule.SingleCancelRequest().post(
      store.state.settings.apiGatewayBase + DEVICE_TOKEN_URL,
      data,
      () => {
        // no further action required at present
        // pushy is storing the token for us
      },
      error => Promise.reject(error),
    );
  },

  /**
   * Unregister device token on logout to prevent anymore notifications
   * being sent to this device
   */
  unregisterDeviceToken() {
    const token = store.getters["user/get_web_push_token"];
    if (!token) {
      // not registered anyway so do nothing
      return;
    }
    navigator.serviceWorker.ready.then(reg => {
      reg.pushManager.getSubscription().then(subscription => {
        subscription.unsubscribe().then(() => {
          // You've successfully unsubscribed
        });
      });
    });
    const client = {
      token,
    };
    this.network_request.post(
      store.state.settings.apiGatewayBase + UNREGISTER_DEVICE_TOKEN_URL,
      client,
      () => {
        localStorage.removeItem("pushyToken");
        localStorage.removeItem("pushyTokenAuth");
      },
      error => Promise.reject(error),
    );
  },
};

export default notifications;
