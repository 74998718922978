<script>
import functions from "../functions"
import { download } from "@/documents";

/**
 * Router State Mixin
 * Exposes router helper functions
 */

export default {
  methods: {
    /**
     * Pushes common parameter updates to the URL.
     * - If no arguments are passed, it will use the current route name and params.
     * @param {String} routeName - The named route. Defaults to the current route.
     * @param {Object} routeParams - Additional route parameters.
     */
    _getRouterObject(routeName, routeParams) {
      const selectedYear = this.selectedYear;
      const selectedCrop = this.selectedCrop;
      const selectedVariety = this.selectedVariety;
      const selectedField = this.selectedField;
      const currentName = this.$route.name;

      let routerObject = {
        name: routeName || currentName,
        params: routeParams || {},
      };

      if (selectedYear) {
        routerObject.params.year = selectedYear.id;
      }

      if (selectedCrop) {
        routerObject.params.crop = selectedCrop.id;
      }

      if (selectedVariety) {
        routerObject.params.crop = selectedVariety.id;
      }

      if (selectedField) {
        routerObject.params.crop = selectedField.id;
      }

      return routerObject;
    },

    mixinPushCommonURLParams(routeName, routeParams) {
      this.$router.push(this._getRouterObject(routeName, routeParams))
    },

    /**
     * **IMPORTANT NOTE**:\
     * This method alters the router's history without its knowledge,
     * this can cause issues with race conditions if more than one component
     * tries to implement this behaviour at a time.\
     * It's a compromise between SPA functionality and deep linking, and
     * should not be treated as a first resort.\
     * If possible, without too much performance detriment, instead use:\
     * *mixinPushCommonURLParams()*
    */
    mixinRouteReplaceStateCommonParams(routeName, routeParams) {
      const { href } = this.$router.resolve(this._getRouterObject(routeName, routeParams));
      window.history.replaceState({}, null, href)
    },

    gotoLocation(location) {
      this.navigateTo({
        path: location,
        query: {
          v: this.$root.api_version,
        },
      });
    },
    gotoRoot() {
      this.navigateTo("/");
    },
    gotoRoute(route) {
      this.navigateTo({
        path: route,
        query: {
          t: +new Date(),
        },
      });
    },
    gotoLogin() {
      this.navigateTo("/users/login");
    },
    gotoAdminLogin() {
      this.gotoRoute("/manager/login");
    },
    gotoTransactions() {
      this.gotoRoute("/farm/marketplace/transactions");
    },
    gotoFarmProfile() {
      this.gotoRoute("/farm/profile");
    },
    gotoRequestProducts() {
      this.navigateTo({
        name: "Marketplace Request Products",
      })
    },
    gotoProductDetails(product_id) {
      this.navigateTo({path: `/product/details/${product_id}`});
    },
    gotoDistributorQuotes() {
      this.gotoRoute("/distributor/quotes/");
    },
    gotoFarmQuotes() {
      this.gotoRoute("/farm/marketplace/requests/quotes/");
    },
    gotoDistributorConfirmation() {
      this.gotoRoute("/distributor/quotes/orders/");
    },
    getPath() {
      return this.$route.path;
    },
    goBack() {
      this.$router.go(window.history.back());
    },
    viewInvoice(invoice) {
      if (invoice.doc_id) {
        let url = `${this.$store.state.settings.apiGatewayBase}/docs/${invoice.doc_id}`;
        this.$http
          .get(url)
          .then((response) => response.json())
          .catch((error) => Promise.reject(error))
          .then((parsed_json) => {
            download(parsed_json);
          });
      } else {
        // TODO remove this function when all invoices are stored in the doc system
        this.$events.fire("load-invoice", invoice.id);

      }
    },
    viewInvoiceHtml(invoice_id) {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/invoice/view/${invoice_id}`, "invoice.html");
    },
    exportTransactionsGroup(group_id, group_type, selected_filters) {
      if (selected_filters) {
        const get_params = Object.entries(selected_filters)
          .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
          .join("&");
        functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/transactions/${group_type}/${group_id}?${get_params}`, "transactions.csv");
        return;
      }

      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/transactions/${group_type}/${group_id}`, "transactions.csv");
    },
  },
};
</script>
