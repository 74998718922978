<script>
import functions from "../../shared/functions"

export default {
  data() {
    return {
      urlbase: this.$root.$data.urlbase,
    };
  },
  methods: {
    gotoChangGroupObject(group) {
      if ('features' in group) {
        this.$events.fire('change-group', group);
      } else {
        this.$http
          .get(`${this.$store.state.settings.apiGatewayBase}/business/businesses/${group.id}/features`)
          .then(response => response.json())
          .catch(error => {
            vm.showMessage("error", "Failed to get group features");
            return Promise.reject(error);
          })
          .then(parsed_json => {
            group['features'] = []
            for (let i = 0; i < parsed_json.objects.length; i++) {
              group['features'].push(parsed_json.objects[i].name);
            }
            this.$events.fire('change-group', group);
          });
      }
    },
    gotoGroupViaID(group_id) {
      const get_profile_url = `${this.$store.state.settings.apiLegacyBase}/api/manager/get-group-profile/${group_id}`;
      this.$http
        .get(get_profile_url)
        .then(response => response.json())
        .catch(error => {
          vm.showMessage("error", "Failed to get group");
          return Promise.reject(error);
        })
        .then(parsed_json => {
          this.gotoChangGroupObject(parsed_json);
        });
    },
    gotoGroup(group) {
      if (typeof group == "number") {
        this.gotoGroupViaID(group);
      } else {
        if ('type' in group) {
          this.gotoChangGroupObject(group)
        } else {
          this.gotoGroupViaID(group.id);
        }
      }


    },

    gotoUserProfile(userID, userEmail) {
      this.navigateTo({
        name: 'User Profile',
        params: {
          userId: userID,
          userEmail: userEmail,
        },
      })
    },
    gotoFieldOverview() {
      this.navigateTo({ name: 'Ingestion Fields' })
    },
    gotoFieldDetailedOverview(groupId, fieldId) {
      this.navigateTo({
        name: 'Ingestion Field Detailed View',
        params: {
          groupId: groupId,
          fieldId: fieldId,
        },
      })
    },
    gotoFarmProfileData(groupId, groupUuid) {
      this.navigateTo(
        {
          name: 'Farm Profile',
          params: {
            groupId: groupId,
            groupUuid: groupUuid,
          },
        },
      )
    },
    gotoFarmProfileEdit(groupId) {
      this.navigateTo({
        name: 'Edit Farm',
        params: {
          groupType: 'farm',
          groupId: groupId,
        },
      });
    },
    gotoCreateFarm() {
      this.navigateTo({
        name: 'Create Farm',
        params: {
          groupType: 'farm',
        },
      });
    },
    gotoCreateAggregator() {
      this.navigateTo({
        name: 'Create Farm',
        params: {
          groupType: 'aggregator',
        },
      });
    },
    gotoCreateSupplier() {
      this.navigateTo({
        name: 'Create Farm',
        params: {
          groupType: 'supplier',
        },
      });
    },
    gotoCreatePartner() {
      this.navigateTo({
        name: 'Create Farm',
        params: {
          groupType: 'partner',
        },
      });
    },
    gotoCreateUser() {
      this.navigateTo({
        name: 'Create User',
      });
    },
    gotoProductAnalysis(productId) {
      this.navigateTo({
        name: 'Products Analysis',
        params: {
          productId: productId,
        },
      });
    },
    gotoRequestDetails(requestId) {
      this.navigateTo({
        name: 'Request Details',
        params: {
          requestId: requestId,
        },
      });
    },
    gotoOtherProducts() {
      this.navigateTo({
        name: 'Other Products',
      });
    },
    gotoOtherProductDetails(otherProductId) {
      this.navigateTo({
        name: 'Other Product Details',
        params: {
          otherProductId: otherProductId,
        },
      });
    },
    gotoProductsUpload() {
      this.navigateTo({
        name: 'Upload Products',
      });
    },
    gotoSupplierProfile(groupId, groupUuid) {
      this.navigateTo({
        name: 'Supplier Profile',
        params: {
          groupId: groupId,
          groupUuid: groupUuid,
        },
      });
    },
    gotoEditSupplier(groupId) {
      this.navigateTo({
        name: 'Edit Farm',
        params: {
          groupType: 'supplier',
          groupId: groupId,
        },
      });
    },
    exportRequestsDistributor(group_id) {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/requests/distributor/all/${group_id}`, "requests.csv");
    },
    exportQuotesDistributor(group_id) {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/quotes/distributor/all/${group_id}`, "quotes.csv");
    },
    exportTransactionsDistributor(group_id) {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/transactions/distributor/all/${group_id}`, "transactions.csv");
    },
    exportRequestsFarm(group_id) {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/requests/farm/all/${group_id}`, "requests.csv");
    },
    exportTransactionsFarm(group_id) {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/transactions/farm/all/${group_id}`, "transactions.csv");
    },
    exportAllFarms() {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/farms/all`, "all-farms.csv");
    },
    exportAllSuppliers() {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/suppliers/all`, "all-suppliers.csv");
    },
    exportAllProducts() {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/products/all`, "all-products.csv");
    },
    exportAllUsers() {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/users/all`, "all-users.csv");
    },
    exportAllDeliveryLocations() {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/delivery-locations/all`, "delivery-locations.csv")
    },
    exportAllTransactions() {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/transactions/all`, "transations.csv");
    },
    exportAllQuotes() {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/quotes/all`, "quotes.csv");
    },
    exportAllRequests() {
      functions.downloadWithAxios(`${this.$store.state.settings.apiLegacyBase}/api/export/requests/all`, "requests.csv");
    },
  },
};
</script>
