/*
-------------------------------------------------------------------------------
Maze
-------------------------------------------------------------------------------
Adds Maze Customer Data Platform to the site.
-------------------------------------------------------------------------------
*/

export const mazeInit = () => {
  (function (m, a, z, e) {
    var s, t;
    try {
      t = m.sessionStorage.getItem("maze-us");
    } catch (err) {
      console.log(err)
    }

    if (!t) {
      t = new Date().getTime();
      try {
        m.sessionStorage.setItem("maze-us", t);
      } catch (err) {
        console.log(err)
      }
    }

    s = a.createElement("script");
    s.src = z + "?t=" + t + "&apiKey=" + e;
    s.async = true;
    a.getElementsByTagName("head")[0].appendChild(s);
    m.mazeUniversalSnippetApiKey = e;
  })(
    window,
    document,
    "https://snippet.maze.co/maze-universal-loader.js",
    "ac92c8be-a58c-4e26-9b51-f124df71fbd2",
  );
};
