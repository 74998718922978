/* eslint-disable camelcase */

/*
-------------------------------------------------------------------------------
Analytics
-------------------------------------------------------------------------------
Description
Adds analytics to the app, if the env_name (site) is set to 'yag.ro'.

- Posthog
- Sentry
- Maze
-------------------------------------------------------------------------------
*/

import store from './store';
import * as Sentry from "@sentry/vue";
import { mazeInit } from './analytics/maze';

const analytics = {

  init() {
    const site = store.getters['settings/env_name'];

    if (site === 'yag.ro') {

      /* Posthog */
      if (window.posthog) {
        posthog.init('phc_njOXYNPMICFK8Z9bGGmRJUQIKBXz1RrZROCs3NfFl5g', {
          api_host: 'https://eu.posthog.com',
          capture_pageview: false, // Handled by vuerouter/index
          opt_in_site_apps: true,
        })
        store.commit('settings/SET_POSTHOG_CLIENT', posthog)
      } else {
        console.error('Posthog not found')
      }

      /* Sentry */
      if (Sentry) {
        Sentry.addIntegration(
          posthog.sentryIntegration({
            organization: 'yagro',
            projectId: '5676129',
          },
          ));
      }

      mazeInit();

    }
  },
};

export default analytics;
