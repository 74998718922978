<script>
import NetworkModule from "../../../lib/NetworkModule";
import UserStateMixin from "./UserStateMixin";
import store from "../../../store";

export default {
  components: {
    UserStateMixin,
  },

  mixins: [
    UserStateMixin,
    //
  ],

  data() {
    return {
      loaded_data: false,
      loading: false,
      error_request_ui: false,
      disabled_message: "",
      request_distributors: new NetworkModule.SingleCancelRequest(),
      request_info: new NetworkModule.SingleCancelRequest(),
    };
  },

  computed: {
    request_count: {
      get() {
        return store.getters["requests/request_count"];
      },
      set(request_count) {
        this.$store.commit("requests/SET_REQUEST_COUNT", request_count);
      },
    },

    selected_products: {
      get() {
        return this.$store.getters["requests/selected_products"];
      },
    },

    selected_delivery_location: {
      get() {
        return this.$store.getters["requests/delivery_location"];
      },
      set(delivery_location) {
        this.$store.commit("requests/SET_DELIVERY_LOCATION", delivery_location);
        // This means that the requests have to be reset as the delivery location means there
        // could be a change of suppliers that are available.
        const editing_product = this.getEditingProduct();
        this.$store.commit("requests/CLEAR_PRODUCT_REQUESTS");
        if (editing_product) {
          this.addProductToRequest(editing_product.product);
        }
      },
    },

    selectedCrop: {
      get() {
        return this.$store.getters["requests/crop"];
      },
      set(crop) {
        this.$store.commit("requests/SET_CROP", crop);
      },
    },

    selected_invoice_entity: {
      get() {
        return this.$store.getters["requests/invoice_entity"];
      },
      set(invoice_entity) {
        this.$store.commit("requests/SET_INVOICE_ENTITY", invoice_entity);
      },
    },

    selected_group: {
      get() {
        return this.$store.getters["requests/group"];
      },
      set(group) {
        this.$store.commit("requests/SET_GROUP", group);
      },
    },
  },

  methods: {
    /*
     * Initialise Request
     */
    getRequestInformation(action_func) {
      const url = `${this.$store.state.settings.apiLegacyBase}/api/farm/request-products/${this.group_id}`;
      this.request_info.get(
        url,
        (parsed_json) => {
          if (parsed_json.success) {
            // Groups, set default value
            this.groups = parsed_json.groups.list;
            if (!this.selected_group) {
              this.selected_group = parsed_json.groups.default;
            }
            // Crops, set default value
            if (!this.selectedCrop) {
              this.selectedCrop = parsed_json.crops.default;
            }
            // Invoice entities, set default value and list of entities
            this.invoice_entities = parsed_json.invoice_entities.list;
            if (!this.selected_invoice_entity) {
              this.selected_invoice_entity =
                parsed_json.invoice_entities.default;
            }
            // Delivery locations, set default value
            if (!this.selected_delivery_location) {
              this.selected_delivery_location =
                parsed_json.delivery_locations.default;
            }
            this.request_count = parsed_json.request_count;
            // Enable the UI
            this.error_request_ui = false;
            this.loaded_data = true;
            if (action_func) {
              action_func();
            }
          } else {
            this.error_request_ui = true;
            this.loaded_data = false;
            this.disabled_message = parsed_json.message;
            vm.showMessage("error", parsed_json.message);
          }
        },
        () => {
          vm.showMessage("error", "Could not get request information.");
          this.loaded_data = false;
        },
      );
    },

    /*
     * Create request object from product selected in typeahead
     */
    addProductToRequest(product) {
      // The product object becomes a request object, so needs attributes adding to it
      if (this.loaded_data) {
        this.addProductToRequestInternal(product, () => {
          this.gotoRequestProducts();
        });
      } else {
        this.getRequestInformation(() => {
          this.addProductToRequestInternal(product, () => {
            this.gotoRequestProducts();
          });
        });
      }
    },

    addProductToRequestInternal(product, action_func) {
      // The product object becomes a request object, so needs attributes adding to it
      if (!product) {
        return;
      }

      const data = {
        group: this.selected_group,
        delivery_location: this.selected_delivery_location,
        product,
      };
      const url = `${this.$store.state.settings.apiLegacyBase}/api/farm/product-distributors/${this.group_id}`;
      this.loading = true;
      this.request_distributors.post(
        url,
        data,
        (parsed_json) => {
          const product_request = {
            product,
            required_by: "",
            comment: "",
            amount: "",
            no_alternatives: 0,
            payment_date: "",
            is_other_product: false,
            selected_distributors: [],
            distributors: [],
            saved: false,
            editing: true,
            other_product: {
              name: "",
              units: "",
              comment: "",
            },
          };
          product_request.distributors = parsed_json.distributors;
          // pre-select all available distributors
          for (let d = 0; d < product_request.distributors.length; d++) {
            const distributor = product_request.distributors[d];
            product_request.selected_distributors.push(distributor.id);
          }

          // Check if other product and set
          if (
            product_request.product &&
            product_request.product.name === "Other Product"
          ) {
            product_request.is_other_product = true;
          }
          // Push to products array
          this.$store.commit("requests/ADD_PRODUCT_REQUEST", product_request);
          this.loading = false;
          if (action_func) {
            action_func();
          }
        },
        () => {
          this.loading = false;
        },
      );
    },

    resetRequest() {
      this.$store.commit("requests/CLEAR_PRODUCT_REQUESTS");
      this.selected_delivery_location = null;
      this.selected_invoice_entity = null;
      this.selected_group = null;
      this.selectedCrop = null;
    },

    /*
     * Send request to API endpoint
     */
    submitRequest() {
      if (this.is_create_request) {
        vm.showMessage("info", "Request already in progress");
        return;
      }

      this.is_create_request = true;
      const data = {
        products: this.selected_products,
        request_information: {
          delivery_location: this.selected_delivery_location,
          invoice_entity: this.selected_invoice_entity,
          group: this.selected_group,
          crop: this.selectedCrop,
        },
      };

      this.$http
        .post(`${this.$store.state.settings.apiLegacyBase}/api/farm/create-requests/${this.group_id}`, data)
        .then((response) => response.json())
        .catch((error) => {
          vm.showMessage("error", "Unable to create request");
          this.is_create_request = false;
          return Promise.reject(error);
        })
        .then((parsed_json) => {
          if (parsed_json.success) {
            vm.showMessage("success", "Request has been submitted");

            this.$nextTick(function () {
              // eslint-disable-line
              this.resetRequest();

              // route to the dashboard
              this.gotoLocation(this.next_url);
            });
          } else {
            vm.showMessage("error", parsed_json.message);
            this.is_create_request = false;
          }
        });
    },

    getEditingProduct() {
      for (let p = 0; p < this.selected_products.length; p++) {
        if (this.selected_products[p].editing) {
          return this.selected_products[p];
        }
      }
      return null;
    },
  },
};
</script>
