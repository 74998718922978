import moment from "moment-timezone/builds/moment-timezone-with-data-10-year-range.js";
import { i18n } from "@/plugins/i18n";

import NetworkModule from "./lib/NetworkModule";
import store from "./store.js";

const user_state = {
  active_auth_backend: null,
  authenticated: false,
  current_terms_accepted: null,
  force_reset_password: null,
  group: {
    features: [],
    id: null,
    name: null,
    type: null,
    uuid: null,
  },
  groupList: [],
  language: null,
  originUrl: null,
  release_notes_read: null,
  user_email: null,
  user_timezone: null,
  web_push_token: "",
};

/* eslint-disable no-param-reassign */
const user_mutations = {
  USER_AUTHENTICATED(state, group) {
    state.authenticated = true;
    state.user_timezone = moment.tz.guess(true);
  },
  LANGUAGE(state, language) {
    i18n.locale = language;
    state.language = language;
  },

  CHANGE_GROUP(state, group) {
    state.group = group;
  },

  GROUP_LIST(state, groupList) {
    state.groupList = groupList;
  },

  USER_NOT_AUTHENTICATED(state) {
    state.authenticated = false;
    state.group = { id: null, uuid: null, name: null, type: null };
    state.current_terms_accepted = null;
    state.force_reset_password = null;
    state.release_notes_read = null;
    state.language = null;
    state.active_auth_backend = null;
  },
  SET_WEB_PUSH_TOKEN(state, token) {
    state.web_push_token = token;
  },

  CLEAR_WEB_PUSH_TOKEN(state) {
    state.web_push_token = null;
  },

  ACTIVE_AUTH_BACKEND(state, backend) {
    state.active_auth_backend = backend;
  },

  FORCE_RESET_PASSWORD(state, force_reset_password) {
    state.force_reset_password = force_reset_password;
  },

  CURRENT_TERMS_ACCEPTED(state, current_terms_accepted) {
    state.current_terms_accepted = current_terms_accepted;
  },

  RELEASE_NOTES_READ(state, release_notes_read) {
    state.release_notes_read = release_notes_read;
  },

  USER_EMAIL(state, user_email) {
    state.user_email = user_email;
  },

  SET_ORIGIN_URL(state, originUrl) {
    state.originUrl = originUrl;
  },

  CLEAR_ORIGIN_URL(state) {
    state.originUrl = null;
  },
};

const user_actions = {
  /**
   * Populates the Vuex store with data from local storage.
   * - Note, localStorage stores this as a string.
   * @param {String} - The user state object (stored as a string).
   */
  initialiseTheStore({ commit, dispatch }) {
    if (localStorage.getItem("user_group")) {
      const data = JSON.parse(localStorage.getItem("user_group"));

      if (data) {
        dispatch(
          "settings/captureEvent",
          { eventName: "store populated from localStorage", payload: data },
          { root: true },
        );
        commit("CHANGE_GROUP", data);
      }
    }
  },
};

const user_getters = {
  get_authenticated(state) {
    return state.active_auth_backend != null;
  },
  get_language(state) {
    return state.language;
  },
  is_farmer(state) {
    return state.type === "farmer";
  },
  is_distributor(state) {
    return state.type === "distributor";
  },
  get_type(state) {
    return state.type;
  },
  get_web_push_token(state) {
    return state.web_push_token;
  },
  get_group(state) {
    return state.group;
  },
  async get_group_list(state) {
    if (state.groupList?.length) {
      return state.groupList;
    } else {
      try {
        const groupURL = `${store.state.settings.apiGatewayBase}/business/autocomplete/list-groups`;
        const groupData = await new NetworkModule.SingleCancelRequest().get(
          groupURL,
        );

        return groupData;
      } catch(err) {
        return (console.error(err), [])
      }
    }
  },
  get_active_backend(state) {
    return state.active_auth_backend;
  },
  get_force_reset_password(state) {
    return state.force_reset_password;
  },
  get_current_terms_accepted(state) {
    return state.current_terms_accepted;
  },
  get_release_notes_read(state) {
    return state.release_notes_read;
  },
  get_user_email(state) {
    return state.user_email;
  },
  get_origin_url(state) {
    return state.originUrl;
  },
};

const user_module = {
  namespaced: true,
  state: user_state,
  getters: user_getters,
  mutations: user_mutations,
  actions: user_actions,
};

export default user_module;
