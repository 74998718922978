import axios from "axios";
import store from "./store.js";
import axios_init from '@/axios_init';

const axiosInstance = axios.create();
axios_init.init(axiosInstance, "0.0.0");

const request_state = {
  selected_products: [],
  delivery_location: null,
  crop: null,
  invoice_entity: null,
  group: null,
  request_count: 0,
};

/* eslint-disable no-param-reassign */
const request_mutations = {
  SET_DELIVERY_LOCATION(state, delivery_location) {
    state.delivery_location = delivery_location;
  },
  CLEAR_DELIVERY_LOCATION(state) {
    state.delivery_location = null;
  },
  SET_CROP(state, crop) {
    state.crop = crop;
  },
  CLEAR_CROP(state) {
    state.crop = null;
  },
  SET_INVOICE_ENTITY(state, invoice_entity) {
    state.invoice_entity = invoice_entity;
  },
  CLEAR_INVOICE_ENTITY(state) {
    state.invoice_entity = null;
  },
  SET_GROUP(state, group) {
    state.group = group;
  },
  CLEAR_GROUP(state) {
    state.group = null;
  },
  SET_REQUEST_COUNT(state, request_count) {
    state.request_count = request_count;
  },
  CLEAR_PRODUCT_REQUESTS(state) {
    state.selected_products = [];
  },
  ADD_PRODUCT_REQUEST(state, product_request) {
    state.selected_products.push(product_request);
  },
  EDIT_PRODUCT_REQUEST(state, index) {
    state.selected_products[index].editing = true;
  },
  DELETE_PRODUCT_REQUEST(state, index) {
    state.selected_products.splice(index, 1);
  },
  CANCEL_PRODUCT_REQUEST(state, index) {
    state.selected_products[index].editing = false;
    if (!state.selected_products[index].saved) {
      state.selected_products.splice(index, 1);
    }
  },
  SAVE_PRODUCT_REQUEST(state, { index, product }) {
    state.selected_products.splice(index, 1, product);
    state.selected_products[index].saved = true;
    state.selected_products[index].editing = false;
  },
};

const request_actions = {
  CLEAR_OPTIONS(context) {
    context.commit("CLEAR_DELIVERY_LOCATION");
    context.commit("CLEAR_INVOICE_ENTITY");
    context.commit("CLEAR_GROUP");
    context.commit("CLEAR_CROP");
  },

  /**
   * Requests a single crop by ID
   * - Useful to find the crop object (name) for a given crop ID
   * @param {Object} context - Vuex context
   * @param {Number} cropId - ID of the crop to request
   * @return {Object} - Crop object
   */
  async getCropById(context, cropId) {
    if (!cropId) return;

    const data = await axiosInstance
      .get(`${store.state.settings.apiLegacyBase}/api/crops/${cropId}`)
      .then((response) => response.json())
      .catch((error) => console.log(error));

    return data;
  },

  /**
   * Gets then sets a single crop by its ID
   * @param {Object} - Vuex context
   * @param {Number} cropId - ID of the crop to request
   */
  async requestToSetCropById({ dispatch, commit }, cropId) {
    if (!cropId) return;

    const data = await dispatch('getCropById', cropId);
    if (data) {
      /* Sets the data in the "selections" store module */
      commit("selections/SET_CROP", data, { root: true });
    }
  },
};

const request_getters = {
  selected_products(state) {
    return state.selected_products;
  },
  delivery_location(state) {
    return state.delivery_location;
  },
  invoice_entity(state) {
    return state.invoice_entity;
  },
  group(state) {
    return state.group;
  },
  crop(state) {
    return state.crop;
  },
};

const requests_module = {
  namespaced: true,
  state: request_state,
  getters: request_getters,
  mutations: request_mutations,
  actions: request_actions,
};

export default requests_module;
