<script>
import FeatureModule from "../../../lib/FeatureModule";
import sidebarLinks from "./sidebar_links";
import auth from "@/auth";
import store from "@/store";
import { getBranding } from "@/branding";

const FEATURE_TYPE = FeatureModule.FEATURE_TYPE;

export default {
  computed: {
    sidebarLinks() {
      let partnerId = getBranding();
      if (this.isFarmGroup(this)) {
        return sidebarLinks.farmLinks(this, store, auth, partnerId);
      } else if (this.isSupplierGroup()) {
        return sidebarLinks.supplierLinks(this, store, auth, partnerId);
      } else if (this.isProductGroup()) {
        return sidebarLinks.productLinks(this, store, auth, partnerId);
      } else if (this.isPartner()) {
        return sidebarLinks.partnerLinks(this, store, auth, partnerId);
      }

      if (auth.isAdmin) {
        return this.managerLinks;
      } else {
        return []
      }
    },
    managerLinks() {
      var links = [];

      if (store.getters['settings/marketplace']) {
        links.push({
          marketplace: [
            {label: "Requests", url: "/manager/requests", id: "sidebar-manager-requests"},
            {label: "Quotes", url: "/manager/quotes", id: "sidebar-manager-quotes"},
            {label: "Orders", url: "/manager/orders", id: "sidebar-manager-orders"},
            {label: "Delivery Locations", url: "/manager/deliverylocations", id: "sidebar-manager-delivery-locations"},
          ],
        });

      }

      links.push({
        products: [
          {label: "Products", url: "/manager/products", id: "sidebar-manager-products"},
          {label: "Other Products", url: "/manager/otherproducts", id: "sidebar-manager-otherproducts"},
          {label: "Categories", url: "/manager/categories", id: "sidebar-manager-categories"},
          {label: "Crops", url: "/manager/crops", id: "sidebar-manager-crops"},
        ],
      });
      if (store.getters['settings/marketplace']) {
        links.push({
          statistics: [{label: "Farm", url: "/manager/statistics", id: "sidebar-manager-statistics"}],
        });
      }

      let groupLinks = [
        {label: "Farms", url: "/manager/farms", id: "sidebar-manager-farms"},
        {label: "Suppliers", url: "/manager/suppliers", id: "sidebar-manager-suppliers"},
        {label: "Partners", url: "/manager/partners", id: "sidebar-manager-partners"},
      ];
      if (store.getters["settings/program_check"]) {
        groupLinks.push({
          label: "Virtual Groups",
          url: "/manager/virtual-groups",
          id: "sidebar-manager-virtual-groups",
        })
      }
      groupLinks.push({label: "Messaging", url: "/manager/message-groups", id: "sidebar-manager-message-groups"})
      links.push({
        groups: groupLinks,
      });

      if (store.getters["settings/price_check"]) {
        links.push({
          "price check": [
            {label: "Analysis", url: "/manager/pricecheck/main", id: "sidebar-manager-analysis"},
            {label: "Data", url: "/manager/pricecheck/data", id: "sidebar-manager-data"},
            {label: "Seasons", url: "/manager/pricecheck/seasons", id: "sidebar-manager-seasons"},
            {label: "Distributors", url: "/manager/pricecheck/distributors", id: "sidebar-manager-distributors"},
          ],
        });
      }
      if (store.getters["settings/price_check"] || store.getters["settings/program_check"] || store.getters["settings/marketwatch"]) {
        let dataLinks = []
        dataLinks.push({label: "Stage 1", url: "/manager/ingestion/holding", id: "sidebar-manager-ingestion-holding"})
        dataLinks.push({label: "Stage 2", url: "/manager/ingestion/upload", id: "sidebar-manager-ingestion-upload"})
        dataLinks.push({label: "View Data", url: "/manager/ingestion/list", id: "sidebar-manager-ingestion-view"})
        dataLinks.push({label: "Ingestion Rules", url: "/manager/ingestion/rules", id: "sidebar-manager-ingestion-rules"})
        dataLinks.push({label: "Fields", url: "/manager/ingestion/fields", id: "sidebar-manager-ingestion-fields"})

        links.push({
          "data ingestion": dataLinks,
        });
      }
      if (store.getters["settings/marketwatch"]) {
        let marketWatchLinks = [];

        marketWatchLinks.push({
          label: "Edit Inputs Data",
          url: "/marketwatch/edit-inputs",
          id: "sidebar-manager-edit-inputs-data",
        })

        links.push({"marketwatch": marketWatchLinks})

      }
      if (store.getters["settings/business_tracker"]) {
        let bustrackLinks = [];
        bustrackLinks.push({
          label: "Budgets",
          url: "/manager/budgets",
          id: "sidebar-manager-bustrack-budgets",
        });
        links.push({"Tracker": bustrackLinks})

      }
      if (store.getters["settings/labour_machinery"]) {
        let labmacLinks = [];
        labmacLinks.push({
          label: "Operations",
          url: "/manager/operations",
          id: "sidebar-manager-labmac-operations",
        });
        labmacLinks.push({
          label: "Fixed Costs",
          url: "/manager/fixed-costs",
          id: "sidebar-manager-labmac-fixed-costs",
        });
        links.push({"Labour & Machinery": labmacLinks})

      }

      links.push({
        "reports": [
          {label: "View Reports", url: "/manager/reports/view", id: "sidebar-manager-reports-view"},
          {
            label: "Farm Specific Reports",
            url: "/manager/reports/view-farm-specific",
            id: "sidebar-manager-reports-view-farm-specific",
          },
          {label: "PDF Reports", url: "/manager/reports/pdfs", id: "sidebar-manager-reports-pdfs"},
          {
            label: "Data Inspector",
            url: "/manager/reports/datainspector",
            id: "sidebar-manager-data-inspector",
          },
          {
            label: "Insights",
            url: "/manager/reports/insights",
            id: "sidebar-manager-reports-insights",
          },
        ],
      });

      links.push({
        users: [{label: "Users", url: "/manager/users", id: "sidebar-manager-users"}],
      });
      let settingsLinks = [{
        label: "Platform Settings",
        url: "/manager/settings",
        id: "sidebar-manager-platform-settings",
      },
      {
        label: "Release Notes",
        url: "/manager/release-notes",
        id: "sidebar-manager-release-notes",
      },
      ]
      if (store.getters['settings/marketplace']) {
        settingsLinks.push({label: "Farm Types", url: "/manager/farmtypes", id: "sidebar-manager-farm-types"})
      }
      settingsLinks.push({label: "Jobs", url: "/manager/jobs", id: "sidebar-manager-jobs"})

      links.push({
        system: settingsLinks,
      });

      return links;
    },

  },
  mounted() {
    this.$root.$emit("bv::toggle::collapse", "collapse_main");
  },
};
</script>
