import auth from "./auth";

function refresh_retry(axios, error, originalRequest) {
  return auth.refresh_promise()
    .then(() => {
      let current_retry_count = 0;
      if (originalRequest.retryattempt) {
        current_retry_count = originalRequest.retryattempt;
      }
      if (current_retry_count === 0) {
        originalRequest.headers.Authorization = auth.getAuthHeader();
        originalRequest.retryattempt = current_retry_count + 1;
        return axios.request(originalRequest);
      } else {
        return Promise.reject(error);
      }
    })
    .catch((ex) => {
      if (ex?.message.includes('404') || ex?.message.includes('500')) {
        return Promise.reject(ex)
      }

      console.log("Logout due to error, after refresh", ex);
      auth.logout("/users/login");
    })
}

const axios_init = {
  init(axios, api_version) {
    // Add JWT Token to all requests
    axios.interceptors.request.use(config => {
      const authHeader = auth.getAuthHeader();

      if (authHeader != null) {
        config.headers["Authorization"] = authHeader; // eslint-disable-line
        if (api_version) {
          config.headers["Accept"] = `application/vnd.yagro-webservice.api+json;version=${api_version}`; // eslint-disable-line
        }
        //config.retryattempt'] = "0"
      }

      if (window.vm.$store?.state?.user?.group?.id) {
        config.headers["X-Yagro-Group-ID"] = window.vm.$store.state.user.group.id;
        config.headers["X-Yagro-Group-UUID"] = window.vm.$store.state.user.group.uuid;
      } else if (window.vm.$store?.state?.selections?.admin_group_field?.id) {
        config.headers["X-Yagro-Group-ID"] = window.vm.$store.state.selections.admin_group_field.id;
        config.headers["X-Yagro-Group-UUID"] = window.vm.$store.state.selections.admin_group_field.uuid;
      } else {
        delete config.headers["X-Yagro-Group-ID"]
        delete config.headers["X-Yagro-Group-UUID"]
      }

      if (config.method == 'get') {
        const axiosAdapter = config.adapter
        let cacheKey = config.headers["X-Yagro-Group-ID"] + "|" + config.url
        config.adapter = (config) => {
          if (config.allowCached) {
            const cached = window.vm.$store.getters.apiCache(cacheKey)
            if (cached) {
              return Promise.resolve(cached)
            }
          }

          return axiosAdapter(config)
            .then((result) => {
              window.vm.$store.commit('cache', { endpoint: cacheKey, value: result })
              return result
            })
        }
      }

      return config;
    });

    axios.defaults.allowCached = false;
    axios.defaults.timeout = 20000;

    axios.interceptors.response.use(
      response => {
        response.json = function() {
          return this.data;
        };
        return response;
      },
      error => {
        if (!error.response) {
          return Promise.reject(error);
        }

        if (error.response.errors && error.response.errors.length > 0) {
          for (let gql_error of error.response.errors) {
            if (gql_error.extensions.code === "TOKEN_EXPIRED") {
              return refresh_retry(axios, error, error.request.config);
            }
          }
        }

        if (error.response.status === 403) {
          return Promise.reject(error);
        } else if (error.response.status === 401) {
          return refresh_retry(axios, error, error.config);
        } else if (error.response.status === 410) {
          window.vm.$events.fire("app-update");
        } else if (
          (error.response.status in [408, 503, 504]) ||
          error.code === "ECONNABORTED" ||
          error.code === "ETIMEDOUT"
        ) {
          window.vm.showMessage("error", "Failed to reach the server");
        }

        return Promise.reject(error);
      },
    );
  },
};

export default axios_init;
